<template>
<n-alert class="rounded-lg" type="warning" size="large">
    <template #header>
        <n-collapse arrow-placement="right">
            <n-collapse-item>
                <template #header>
                    <div class="text-xs" v-html="parsed[0]"></div>
                </template>
                <div
                    class="notes text-xs"
                    v-html="parsed.slice(1).join('')">
                </div>
            </n-collapse-item>
        </n-collapse>
    </template>
</n-alert>

<div v-if="init">
    <super-table
        :filters="filters"
        :actions="actions"
        :refFilters="refFilters"
        :pager="marketplace.pager"
        :columns="marketplace.columns"
        :records="marketplace.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_marketplace-buy"
        @getData="getData"
        @sortColumn="sortColumn"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2">
                {{ marketplace.localization['marketplace/buy/grid/title'] }}:
                <span class="text-main font-semibold text-lg">{{ marketplace.total }}</span>
            </div>
        </template>
    </super-table>
</div>
<bot-review v-if="marketplace.localization" :show="showModal" />
</template>

<script>
// general
import general from './general';

// naive-ui
import { NSpin, NCard, NSkeleton, NDivider } from 'naive-ui';

// naive-ui
import {
    NAlert,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import SuperTable from '@components/super-table/desktop.vue';
import FilterAlgos from '@components/filters/algos.vue';
import FilterCoins from '@components/filters/coins.vue';
import GridTable from '@components/base/grid-table.vue';
import BotReview from '@components/bot-review/index.vue';
import GridSettings from '@components/base/grid-settings.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterChangedDate from '@components/filters/changedDate.vue';

export default {
    name: 'marketplace-buy-desktop',
    components: {
        NCard,
        NSpin,
        NAlert,
        NDivider,
        BotReview,
        NSkeleton,
        GridTable,
        NCollapse,
        SuperTable,
        FilterCoins,
        FilterAlgos,
        GridSettings,
        NCollapseItem,
        FilterExchanges,
        FilterChangedDate,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.buy-info {
  :deep(p):not(:first-child) {
    margin-top: 14px;

    &[style='color:red'] {
      color: rgb(208, 58, 82) !important;
    }
  }

  :deep(b) {
    color: #4949D9;
  }

  :deep(a) {
    text-decoration: underline;
  }

  :deep(ul) {
    margin-top: 14px;
    list-style: circle;
    padding-left: 24px;
  }
}
</style>